<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>주문/결제하기</span>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>구매 수량</dt>
                <dd>{{ productList.length }}개</dd>
              </dl>
              <dl>
                <dt>판매가(개당)</dt>
                <dd>
                  {{ $Util.formatNumber2(marketInfo.price, { unit: '' }) }}원
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>상품명</dt>
                <dd>{{ marketInfo.title }}</dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ buyerInfo.name }}</span>
                  <span>{{ buyerInfo.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="!$Util.isEmpty(buyerInfo.login_type)"
                  >
                    {{
                      Number(buyerInfo.login_type) === 0
                        ? '카카오'
                        : Number(buyerInfo.login_type) === 1
                        ? '네이버'
                        : Number(buyerInfo.loginType) === 2
                        ? '구글'
                        : '애플'
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>보유중인 캐시</dt>
                <dd style="display: flex; align-items: center;">
                  <div>
                    <span class="color">{{
                      $Util.formatNumber2(buyerInfo.cash, { unit: '' })
                    }}</span>
                    C
                  </div>
                  <button class="btn_wizzcash">
                    <div @click="fnCashCharge" class="btn_text">충전하기</div>
                  </button>
                </dd>
              </dl>
              <dl>
                <dt>보유중인 포인트</dt>
                <dd>
                  <span class="color">{{
                    $Util.formatNumber2(buyerInfo.point, { unit: '' })
                  }}</span>
                  P
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="marketInfo.type == '1'" class="lump">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                구매자 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="marketInfo.reqKakao == '1'" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        @input="maskingResKakao"
                        :value="options.resKakao"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="marketInfo.reqPhone == '1'" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요(숫자만)"
                        @input="maskingResPhone"
                        :value="options.resPhone"
                        :maxlength="11"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="marketInfo.reqEmail == '1'" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        @input="maskingResEmail"
                        :value="options.resEmail"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="blue_line" style="margin-bottom: 0;">
            <div class="inner none-padding">
              <div>상품 금액</div>
              <div>
                {{ $Util.formatNumber2(price, { unit: ' 원' }) }}
              </div>
            </div>
            <div class="inner none-padding">
              <div>사용 포인트</div>
            </div>
            <div class="form">
              <div class="form_in">
                <div class="inline">
                  <label class="input">
                    <label class="input">
                      <input
                        type="number"
                        :value="inputPoint"
                        @input="maskingInputPoint"
                        :min="0"
                        inputmode="decimal"
                        pattern="\d*"
                        placeholder="사용할 포인트 입력"
                        :disabled="
                          !($Util.isEmpty(form.point) || Number(form.point) < 1)
                        "
                      />
                    </label>
                  </label>
                  <button
                    class="btn"
                    :class="{
                      bo: $Util.isEmpty(inputPoint) || Number(inputPoint) < 100
                    }"
                    @click="fnApply"
                    v-if="$Util.isEmpty(form.point) || Number(form.point) < 1"
                  >
                    사용</button
                  ><button class="btn bo" @click="fnInitPoint" v-else>
                    취소</button
                  ><!--'포인트 입력 시: bo 삭제'-->
                </div>
                <div class="hint">* 100P 단위 이상 사용</div>
              </div>
            </div>
            <div class="inner">
              <div>수수료</div>
              <div>{{ $Util.formatNumber2(fees, { unit: ' 원' }) }}</div>
            </div>
            <div class="inner plain">
              <div>포인트 사용</div>
              <div>
                {{ $Util.formatNumber2(form.point || 0, { unit: ' 원' }) }}
              </div>
            </div>
            <div
              class="inner plain"
              v-if="selectedPayType === $ConstCode.PAY_TYPE['2'].value"
            >
              <div>캐시 할인</div>
              <div>
                {{ $Util.formatNumber2(computedCashDiscount, { unit: ' 원' }) }}
              </div>
            </div>
            <div class="inner result">
              <div>총 결제액</div>
              <div>{{ $Util.formatNumber2(form.cash, { unit: ' 원' }) }}</div>
            </div>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl" style="color: #999; font-weight: 500;">
                    결제 방법 선택
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="select" style="flex: 1;">
                        <select v-model="selectedPayType">
                          <option
                            :value="item.value"
                            v-for="(item, index) in Object.values(
                              $ConstCode.PAY_TYPE
                            )"
                            :key="index"
                            >{{ item.name }}</option
                          >
                        </select>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnCancel">취소</button>
              <button class="btn" @click="fnPay">결제하기</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <div>
                    * 수수료는 카드수수료(3.3%)를 합한 금액입니다.
                  </div>
                  <div>
                    - 위즈캐시 결제 시 카드수수료 면제
                  </div>
                  <div>
                    * 결제일로부터 3일이 지나면 자동으로 구매 확정 처리가
                    됩니다.
                  </div>
                  <div>
                    * 구매확정 진행 후 환불 요청이 불가능합니다.
                  </div>
                  <div>
                    * 환불 요청시 판매자 의사에 따라 반려 처리될 수 있습니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <settle v-bind:="settleForm" :callback="fnCallback" />
      <nice
        :id="'formNice15'"
        :m="niceForm.m"
        :encode-data="niceForm.encodeData"
        :call-type="niceForm.callType"
        :rtn-url="rtnUrl"
        :fail-rtn-url="failRtnUrl"
        :callback="fnCallbackNice"
      />
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import Settle from '@/components/common/settle/index.vue'

export default {
  name: 'market-pay-index',
  components: {
    Settle
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
